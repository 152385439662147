import React from 'react';

const packages = [
  {
    title: "QuickLaunch Essential",
    description: "Perfect for businesses needing a powerful, professional website. This package is ideal for businesses looking to make a strong impression online with a professionally managed website.",
    price: "Pricing starts at $75/month",
    features: [
      "A fully customized, high-performance digital platform",
      "A sleek, professional design tailored to your brand",
      "A dedicated team of experts managing your site",
      "24/7 business support online",
    ],
  },
  {
    title: "QuickLaunch Pro",
    description: "Boost your brand with a custom website and professional email system. QuickLaunch Pro is perfect for businesses that want a professional website and the ability to communicate efficiently with a branded email system.",
    price: "Pricing starts at $150/month",
    features: [
      "A custom, high-performance website tailored to your brand",
      "Custom domain email addresses for your team, enhancing credibility and professional communication",
	  "Streamlined email and website management, all in one place for hassle-free updates",
      "Ongoing maintenance and 24/7 support for both your website and email, ensuring everything runs smoothly",
     ],
  },
  {
    title: "QuickLaunch Premium",
    description: "A complete digital solution – website, email, and VoIP phone system, fully managed. QuickLaunch Premium is designed for businesses that need a full-scale digital infrastructure, including professional communication tools, all managed by a dedicated team.",
    price: "Pricing starts at $250/month",
    features: [
      "Everything included in the Pro package",
      "Unlimited extensions for your team",
      "Interactive voice response capability",
      "Call queues",
      "Call recording",
      "Voicemail to email (optional)",
      "Mobile & PC friendly",
      "Full support for managing your website, email, and phone systems, ensuring your business stays connected and operates smoothly",
    ],
  },
  {
    title: "The Powerhouse Package",
    description: "The Powerhouse Package is perfect for businesses looking for a cost-effective way to gain visibility and build momentum. With a highly targeted Google Ads campaign and detailed reporting, you'll get the exposure you need without the heavy commitment.",
    price: "Pricing starts at $1,075/month (excludes one-time setup fee).",
    features: [
      "Tailored keyword strategy",
      "Competitive market research",
      "Custom audience targeting",
      "Geographic-based ad targeting",
      "Compelling ad design and copywriting",
      "Remarketing to boost engagement",
      "Google My Business setup and optimization",
      "Comprehensive performance reporting",
      "Conversion tracking to measure success",
    ],
  },
];

function Portfolio() {
    return (
      <div className="my-4 py-4" id="portfolio">
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Packages</h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
        </div>
  
        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            {packages.map((pkg, index) => (
              <div key={index} className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 flex flex-col h-full">
                <div className="m-2 text-sm flex-grow">
                  <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">{pkg.title}</h4>
                  <p className="text-md font-medium leading-5 mb-4">
                    {pkg.description}
                  </p>
                  <p className="text-md font-bold mt-4">{pkg.price}</p>
                  <h5 className="font-semibold mt-4 mb-2">What's Included:</h5>
                  <ul className="space-y-2 mb-4">
                    {pkg.features.map((feature, idx) => (
                      <li key={idx} className="flex items-start space-x-2">
                        <span className="text-blue-600 mt-1">•</span>
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-auto">
                  <a href="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-xl rounded-xl">
                    Learn More
                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  
  export default Portfolio;